<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="order_id" label="订单编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="clerk_uid" label="核销员用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="cashier_nickname" label="核销员用户昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="store_title" label="核销门店" show-overflow-tooltip></el-table-column>
      <el-table-column label="核销时间" show-overflow-tooltip>
        <template v-slot="s">
          {{s.row.created_at | date}}
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>

export default {
  components:{},
  name: "manage",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      editBox:false,
      editForm:{

      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.shop.plugin.scanGoods.cashierLog({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>